import React from 'react'
import { ThemeProvider } from './component/darkMode/ThemeContext'
import Background from './component/darkMode/Background'
import Header from './component/header/Header'
import Profile from './component/profile/Profile'
import Navbar from './component/navbar/Navbar'
import Projects from './page/projects/Projects'
import Certificates from './page/certificates/Certificates'
import Blog from './page/blog/Blog'
import Contact from './page/contact/Contact'
import { BrowserRouter, Route, Routes } from 'react-router-dom'

const App = () => {
    return (
        <BrowserRouter>
            <ThemeProvider>
                <Background>
                    <Header />
                    <Profile />
                    <Navbar />
                    <Routes>
                        <Route path="/" element={<Projects />} />
                    </Routes>
                    <Routes>
                        <Route path="/blog" element={<Blog />} />
                    </Routes>
                    <Routes>
                        <Route
                            path="/certificates"
                            element={<Certificates />}
                        />
                    </Routes>
                    <Routes>
                        <Route path="/contact" element={<Contact />} />
                    </Routes>
                </Background>
            </ThemeProvider>
        </BrowserRouter>
    )
}

export default App
