export const data = [
  {
    id: "1",
    project_name: "Mofe",
    project_link: "https://mofe.co.id/",
    project_image:
      "https://i.pinimg.com/originals/a9/0d/7d/a90d7dd198e0adf80c0df6364b3222ce.jpg",
    certificate_name: "Front-End Engineer Career Path",
    certificate_link:
      "https://www.codecademy.com/profiles/raview69/certificates/5f85dd867b67b60014ac9ea3",
    certificate_image:
      "https://i.pinimg.com/originals/b6/e1/80/b6e180e631aa6c5dbebcd1e4d4fa8c34.jpg",
    blog_name: "React + Eslint + Prettier + Tailwind Configuration",
    blog_link:
      "https://dev.to/raview69/react-eslint-prettier-tailwind-configuration-ek8",
    blog_image:
      "https://i.pinimg.com/originals/95/2a/ef/952aefc7565c5e6045de712ed3b8edce.jpg",
  },
  {
    id: "2",
    project_name: "Questeon",
    project_link: "https://questbuilder.questeon.co",
    project_image:
      "https://i.pinimg.com/originals/86/a1/2a/86a12a28acdf54644a697c4941741d49.jpg",
    certificate_name: "Web Development Skill Path",
    certificate_link:
      "https://www.codecademy.com/profiles/raview69/certificates/af00e5032d0a68cc84879983f5d8333b",
    certificate_image:
      "https://i.pinimg.com/originals/32/73/50/327350f0b992065e8431022a52f4e582.jpg",
    blog_name: "Crypto Alert Website",
    blog_link:
      "https://medium.com/@raviwicaksono69/crypto-alert-website-a624b995a1b",
    blog_image:
      "https://i.pinimg.com/originals/84/8d/56/848d5623a1dce2971ba657b9f624ab4d.jpg",
  },
  {
    id: "3",
    project_name: "Poinswap",
    project_link: "https://poinswap.com/",
    project_image:
      "https://i.pinimg.com/originals/00/29/fb/0029fbe16076ca6eaee9bbe53463e8a6.jpg",
    certificate_name: "React Course",
    certificate_link:
      "https://www.codecademy.com/profiles/raview69/certificates/5f85dd867b67b60014ac9ea3",
    certificate_image:
      "https://i.pinimg.com/originals/32/73/50/327350f0b992065e8431022a52f4e582.jpg",
  },
  {
    id: "4",
    project_name: "Phangkey",
    project_link: "https://form.phangkey.com/",
    project_image:
      "https://i.pinimg.com/originals/95/2c/a0/952ca0d270859938db53b9616f36f005.jpg",
    certificate_link: "",
    certificate_image: "",
  },
  {
    id: "5",
    project_name: "Coin Alert",
    project_link: "https://colisar-edale-bures.netlify.app/",
    project_image:
      "https://i.pinimg.com/originals/ce/85/1a/ce851a9366cc56d6cd555d8013df82ce.jpg",
    certificate_name: "",
    certificate_link: "",
    certificate_image: "",
  },
  {
    id: "6",
    project_name: "Avionic.id",
    project_link: "https://avionic.id/",
    project_image:
      "https://i.pinimg.com/originals/bd/0b/44/bd0b445a04212172c785f8d934d78330.jpg",
    certificate_link: "",
    certificate_image: "",
  },
  {
    id: "7",
    project_name: "Menu App",
    project_link: "https://lustrous-pothos-60a292.netlify.app/",
    project_image:
      "https://i.pinimg.com/originals/b9/d9/15/b9d9156f92b4c59116e3297e811c3b8e.jpg",
    certificate_link: "",
    certificate_image: "",
  },
  {
    id: "8",
    project_name: "Todo App",
    project_link: "https://vocal-malabi-afd398.netlify.app/",
    project_image:
      "https://i.pinimg.com/originals/bf/c6/c9/bfc6c965970d866b7a00d4bfe1ac6251.jpg",
    certificate_link: "",
    certificate_image: "",
  },
  {
    id: "9",
    project_name: "Codecademy Store",
    project_link: "https://super-duckanoo-bf940e.netlify.app/",
    project_image:
      "https://i.pinimg.com/originals/1f/d8/42/1fd842bbc7a1d109a10713a842dade6c.jpg",
    certificate_link: "",
    certificate_image: "",
  },
  {
    id: "10",
    project_name: "Reddit Minimal",
    project_link: "https://reddit-minimal-clone-ag907qjl9-raview69.vercel.app/",
    project_image:
      "https://i.pinimg.com/originals/9e/0a/82/9e0a82e2f770b42aa01e345347545c64.jpg",
    certificate_link: "",
    certificate_image: "",
  },
  {
    id: "11",
    project_name: "Quote Generator",
    project_link: "https://raview69.github.io/quote-generator/public/",
    project_image:
      "https://i.pinimg.com/originals/19/d9/66/19d966cecb03e4a7f9e004e6d0488681.jpg",
    certificate_link: "",
    certificate_image: "",
  },
  {
    id: "12",
    project_name: "Number Guesser",
    project_link: "https://raview69.github.io/js_challenges/NumberGuesser/",
    project_image:
      "https://i.pinimg.com/originals/2c/fa/ce/2cface508bbe9762fb2ea0a629ccbbff.jpg",
    certificate_link: "",
    certificate_image: "",
  },
  {
    id: "13",
    project_name: "Film Finder",
    project_link: "https://raview69.github.io/js_challenges/FilmFinder/",
    project_image:
      "https://i.pinimg.com/originals/c9/d8/3d/c9d83ddbb15e53d3f1dad0e137c9d8a5.jpg",
    certificate_link: "",
    certificate_image: "",
  },
  {
    id: "14",
    project_name: "Shortlink Maker",
    project_link: "https://raview69.github.io/js_challenges/ShortlinkMaker/",
    project_image:
      "https://i.pinimg.com/originals/54/57/e1/5457e11bd49586f8212a045a101030a2.jpg",
    certificate_link: "",
    certificate_image: "",
  },
  {
    id: "15",
    project_name: "Word Suggestion",
    project_link: "https://raview69.github.io/js_challenges/WordSuggestion/",
    project_image:
      "https://i.pinimg.com/originals/10/6d/2a/106d2ab43ef721e5d8e3e14d95653425.jpg",
    certificate_link: "",
    certificate_image: "",
  },
];
