import React from 'react'
import Icon from '../iconList/Icon'
import Cartoon from '../UI/cartoon/Cartoon'

const Profile = () => {
    return (
        <div className="flex items-center justify-center dark:text-white">
            <div>
                <Icon />
                <div className="text-3xl text-center sm:py-3 xl:py-5 sm:text-5xl lg:text-6xl">
                    MUHAMAD RAVI WICAKSONO
                </div>
                <Cartoon />
            </div>
        </div>
    )
}

export default Profile
