import React, { useRef } from 'react'
import emailjs from '@emailjs/browser'
import Swal from 'sweetalert2'

const Contact = () => {
    const form = useRef()
    const sendEmail = (e) => {
        e.preventDefault()

        emailjs
            .sendForm(
                'service_my0gbx7',
                'template_06lim2j',
                form.current,
                'OGKGuXcSuKppeKkXv'
            )
            .then(
                (result) => {
                    console.log(result.text)
                    Swal.fire({
                        icon: 'success',
                        title: 'Message Sent Successfully',
                    })
                },
                (error) => {
                    console.log(error.text)
                    Swal.fire({
                        icon: 'error',
                        title: 'Ooops, something went wrong',
                        text: error.text,
                    })
                }
            )
        e.target.reset()
    }
    return (
        <div className="items-center justify-center mt-4">
            <div className="flex items-center justify-center py-2 lg:py-4">
                <form
                    ref={form}
                    onSubmit={sendEmail}
                    className=" dark:text-white sm:text-xl "
                >
                    <label>Name :</label>
                    <br />
                    <input
                        type="text"
                        name="user_name"
                        className="w-52 sm:w-80 lg:w-96 border-2 border-black rounded-sm dark:border-white dark:text-black"
                    />
                    <br />
                    <br />
                    <label>Email :</label>
                    <br />
                    <input
                        type="email"
                        name="user_email"
                        className="w-52 sm:w-80 lg:w-96 border-2 border-black rounded-sm dark:border-white dark:text-black"
                    />
                    <br />
                    <br />
                    <label>Phone :</label>
                    <br />
                    <input
                        type="text"
                        name="user_phone"
                        className="w-52 sm:w-80 lg:w-96 border-2 border-black rounded-sm dark:border-white dark:text-black"
                    />
                    <br />
                    <br />
                    <label>Message :</label>
                    <br />
                    <textarea
                        name="message"
                        className="w-52 sm:w-80 lg:w-96 border-2 border-black rounded-sm dark:border-white dark:text-black"
                    />
                    <br />
                    <br />
                    <input
                        type="submit"
                        value="Send"
                        className="border-2 border-black rounded-sm px-3 py-1 dark:border-white"
                    />
                    <br />
                </form>
            </div>
        </div>
    )
}

export default Contact
