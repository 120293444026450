import React from 'react'
import {
    FaGitlab,
    FaTwitter,
    FaGithub,
    FaLinkedin,
    FaDev,
} from 'react-icons/fa'

const Icon = () => {
    return (
        <div className="flex items-center justify-center py-2">
            <div className="px-2 sm:px-4">
                <a href="https://twitter.com/RaviWicaksono2">
                    <FaTwitter className="w-8 h-8 sm:w-10 sm:h-10 " />
                </a>
            </div>
            <div className="px-2 sm:px-4">
                <a href="https://gitlab.com/raview69">
                    <FaGitlab className="w-8 h-8 sm:w-10 sm:h-10 " />
                </a>
            </div>
            <div className="px-2 sm:px-4">
                <a href="https://github.com/raview69">
                    <FaGithub className="w-8 h-8 sm:w-10 sm:h-10 " />
                </a>
            </div>
            <div className="px-2 sm:px-4">
                <a href="https://www.linkedin.com/in/muhamad-ravi-wicaksono-780637190/">
                    <FaLinkedin className="w-8 h-8 sm:w-10 sm:h-10 " />
                </a>
            </div>
            <div className="px-2 sm:px-4">
                <a href="https://dev.to/raview69">
                    <FaDev className="w-8 h-8 sm:w-10 sm:h-10 " />
                </a>
            </div>
        </div>
    )
}

export default Icon
