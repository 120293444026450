import React from 'react'
import CartoonRavi from '../../image/ravicartoon.json'
import Lottie from 'lottie-react'

const Cartoon = () => {
    return (
        <div className="flex items-center justify-center">
            <div className="w-72 sm:w-80 xl:96">
                <Lottie animationData={CartoonRavi} loop={true} />
            </div>
        </div>
    )
}

export default Cartoon
