import React from 'react'
import { NavLink } from 'react-router-dom'
import Toggle from '../darkMode/ThemeToggle'
import icon from '../image/logo.svg'

const Header = () => {
    return (
        <div className="flex items-center justify-between p-5">
            <NavLink to="/">
                <img src={icon} alt="" className="w-8 sm:w-10" />
            </NavLink>
            <div>
                <Toggle />
            </div>
        </div>
    )
}

export default Header
