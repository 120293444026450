import React from 'react'
import { data } from '../../data.js'

const Projects = () => {
    const listProjects = data.map((project, index) => (
        <li
            key={index}
            className="flex items-center justify-center overflow-hidden py-2"
        >
            <a
                href={project.project_link}
                className="border-2 dark:border-white w-80 rounded-sm sm:w-6/12"
            >
                <div className="w-full">
                    <img
                        src={project.project_image}
                        className="md:w-full sm:w-auto"
                    />
                    <div className="p-2 overflow-hidden mr-2">
                        <h3 className="text-base py-2 sm:text-lg">
                            {project.project_name}
                        </h3>
                        <p className="text-sm py-2 sm:text-base">
                            {project.project_link}
                        </p>
                    </div>
                </div>
            </a>
        </li>
    ))
    return (
        <div className="items-center justify-center mt-4">
            <ul className="items-center justify-center dark:text-white">
                {listProjects}
            </ul>
        </div>
    )
}

export default Projects
