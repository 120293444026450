import React from 'react'
import { NavLink } from 'react-router-dom'

const Navbar = () => {
    return (
        <nav className="flex items-center justify-between p-2 rounded-lg border-2 border-black dark:border-white mx-8 sm:mx-20 lg:mx-60">
            <NavLink
                to="/"
                className={({ isActive }) =>
                    isActive
                        ? 'bg-black text-white dark:bg-white dark:text-black py-2 px-3 -m-2 sm:px-8 lg:px-10'
                        : 'py-2 px-3 -m-2 sm:px-8 lg:px-10 dark:text-white'
                }
            >
                Projects
            </NavLink>
            <NavLink
                to="/blog"
                className={({ isActive }) =>
                    isActive
                        ? 'bg-black text-white dark:bg-white dark:text-black py-2 px-3 -m-2 sm:px-8 lg:px-10'
                        : 'py-2 px-3 -m-2 sm:px-8 lg:px-10 dark:text-white'
                }
            >
                Blog
            </NavLink>
            <NavLink
                to="/certificates"
                className={({ isActive }) =>
                    isActive
                        ? 'bg-black text-white dark:bg-white dark:text-black py-2 px-3 -m-2 sm:px-8 lg:px-10'
                        : 'py-2 px-3 -m-2 sm:px-8 lg:px-10 dark:text-white'
                }
            >
                Certificates
            </NavLink>
            <NavLink
                to="/contact"
                className={({ isActive }) =>
                    isActive
                        ? 'bg-black text-white dark:bg-white dark:text-black py-2 px-3 -m-2 sm:px-8 lg:px-10'
                        : 'py-2 px-3 -m-2 sm:px-8 lg:px-10 dark:text-white'
                }
            >
                Contac me
            </NavLink>
        </nav>
    )
}

export default Navbar
